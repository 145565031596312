import React, { useState } from "react"
import { PageProps } from "gatsby"
import { useAuth } from "./auth"
import { Inspector } from "./Inspection"
import Version from "components/version"

import {
  getDatabase,
  ref,
  onValue,
  connectDatabaseEmulator,
} from "firebase/database"
import { onDisconnect, set } from "firebase/database"
import { getFirestore, doc, serverTimestamp, setDoc } from "firebase/firestore"

const version = "9.0.0"
const inspector = null
const loading = true
export type ContextType = {
  version: string
  inspector: Inspector | null
  loading: boolean
}

const Context = React.createContext<ContextType>({
  version,
  inspector,
  loading,
})

const Provider = ({ children }: PageProps) => {
  const database = getDatabase()
  // if (location.hostname === "localhost") {
  //   // Point to the RTDB emulator running on localhost.
  //   connectDatabaseEmulator(database, "127.0.0.1", 9000)
  // }

  const [loading, setLoading] = useState<boolean>(true)
  const inspector = useAuth(() => setLoading(false))
  if (inspector) {
    const status = ref(database, `/status/${inspector?.uid}`)
    const connected = ref(database, ".info/connected")

    var isOffline = {
      state: "offline",
      last_changed: serverTimestamp(),
    }
    var isOnline = {
      state: "online",
      last_changed: serverTimestamp(),
    }
    onValue(connected, snapshot => {
      if (snapshot.val() == false) return

      onDisconnect(status)
        .set(isOffline)
        .then(() => set(status, isOnline))
    })
  }

  return (
    <Context.Provider value={{ version, inspector, loading }}>
      {/* <Loader active={loading} msg="Initializing App" /> */}
      {children}
      <Version version={version} />
    </Context.Provider>
  )
}

export default Context
export { Provider }
